import {
  A,
  cache,
  createAsync,
  useLocation,
  useNavigate,
  useParams,
} from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  Match,
  onMount,
  Show,
  Suspense,
  Switch,
  useTransition,
} from "solid-js";
import { GiftCardBack } from "~/components/pluto/gift_card_back";
import { GiftCardFront } from "~/components/pluto/gift_card_front";
import { usePluto } from "~/context/pluto";
import {
  getGiftById,
  updateGiftOccasion,
  updateGiftSelection,
} from "~/server/apis/client_apis";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { GiftCardOccasion } from "~/server/types/pluto";
import { PhosphorIcon } from "~/widgets/icons";

interface CardSideState {
  side: "front" | "back";
}

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export default function EditGiftPage() {
  const location = useLocation<CardSideState>();

  const initialTab = location.state?.side === "back" ? "back" : "front";
  const [tab, setTab] = createSignal(initialTab);
  const [pending, start] = useTransition();
  const navigate = useNavigate();
  const [updatedTextFields, setUpdatedTextFields] =
    createSignal<GiftCardOccasion | null>(null);

  const params = useParams();

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const { plutoGifts, updateGiftCardDetails } = usePluto();

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    plutoGifts?.newGiftCardDetails ?? routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  const selectionData = createMemo(() => ({
    titleId: giftCardDetails()?.content.selection.titleId ?? "1",
    activities: giftCardDetails()?.content.selection.activities ?? {
      "1": "1",
      "2": "1",
      "3": "1",
    },
    activityImageId:
      giftCardDetails()?.content.selection.activityImageId ??
      giftCardDetails()?.content.images.activityImages?.[0]?.id ??
      "",
    occasionImageId:
      giftCardDetails()?.content.selection.occasionImageId ??
      giftCardDetails()?.content.images.occasionImages?.[0]?.id ??
      "",
  }));

  const [updatedSelection, setUpdatedSelection] = createSignal(selectionData());

  createEffect(() => {
    if (giftCardDetails()) {
      setUpdatedSelection(selectionData());
    }
  });

  const updateTab = (side: string) => () => start(() => setTab(side));

  const updateGiftCardOccasion = async () => {
    try {
      if (updatedTextFields()) {
        updateGiftCardDetails("content.text.occasion", updatedTextFields());

        const response = await updateGiftOccasion(
          params.giftId,
          updatedTextFields()!
        );

        if (response.status === 200) {
          navigate(`/gifts/${params.giftId}`);
        }
      }
    } catch (error) {
      console.error("Failed to update gift occasion:", error);
    }
  };

  const updateGiftCardSelection = async () => {
    try {
      updateGiftCardDetails("content.text.selection", updatedSelection());
      const response = await updateGiftSelection(
        params.giftId,
        updatedSelection()
      );
      if (response.status === 200) {
        navigate(`/gifts/${params.giftId}`);
      }
    } catch (error: any) {
      console.error("Failed to update gift selection:", error);
    }
  };

  return (
    <div class="flex h-full w-full flex-col md:w-[800px]">
      <div class="relative flex min-h-14 items-center justify-center px-4 py-2">
        <A href={`/gifts/${params.giftId}`} class="absolute left-4 top-4">
          <PhosphorIcon
            name="x"
            fontSize={20}
            size="thin"
            class={`cursor-pointer text-white`}
          />
        </A>
        <p class="text-center text-smallBold uppercase tracking-[1.1px] text-white">
          Making thoughtful changes
        </p>
      </div>
      <div class="border-b border-basePrimaryMedium px-4">
        <nav class="flex gap-x-1">
          <button
            type="button"
            class="w-[171.5px] cursor-pointer px-2.5 py-3 text-medium transition-colors duration-300"
            classList={{
              "text-white border-b-[1px] border-white": tab() === "front",
              "text-basePrimaryLight": tab() !== "front",
            }}
            onClick={updateTab("front")}
          >
            Front - Wish message
          </button>
          <button
            type="button"
            class="w-[171.5px] cursor-pointer px-2.5 py-3 text-medium transition-colors duration-300"
            classList={{
              "text-white border-b-[1px] border-white": tab() === "back",
              "text-basePrimaryLight": tab() !== "back",
            }}
            onClick={updateTab("back")}
          >
            Back - The gift
          </button>
        </nav>
      </div>
      <div
        class="mx-auto overflow-y-auto p-4"
        classList={{ pending: pending() }}
      >
        <Suspense fallback={<div class="loader">Loading...</div>}>
          <Switch>
            <Match when={tab() === "front"}>
              <div class="min-h-[560px] w-[343px] rounded-3xl bg-plutoGiftCard">
                <Show when={giftCardDetails()}>
                  <GiftCardFront
                    giftDetails={() => giftCardDetails()!}
                    isEditable={true}
                    onUpdate={setUpdatedTextFields}
                  />
                </Show>
              </div>
              <div class="flex flex-col gap-3 py-4">
                <button
                  class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
                  onClick={updateGiftCardOccasion}
                >
                  <p class="text-buttonMedium font-bold text-textDark">Save</p>
                </button>
                <button class="flex h-[44px] w-full items-center justify-center rounded-[41px] border border-baseSecondaryDark px-4">
                  <p class="text-buttonMedium text-white">Receiver's preview</p>
                </button>
              </div>
            </Match>
            <Match when={tab() === "back"}>
              <div class="min-h-[560px] w-[343px] rounded-3xl bg-plutoGiftCard">
                <Show when={giftCardDetails()}>
                  <GiftCardBack
                    giftDetails={() => giftCardDetails()!}
                    isEditable={true}
                    onUpdate={setUpdatedSelection}
                  />
                </Show>
              </div>
              <div class="flex flex-col gap-3 py-4">
                <button
                  class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
                  onClick={updateGiftCardSelection}
                >
                  <p class="text-buttonMedium font-bold text-textDark">Save</p>
                </button>
                <button class="flex h-[44px] w-full items-center justify-center rounded-[41px] border border-baseSecondaryDark px-4">
                  <p class="text-buttonMedium text-white">Receiver's preview</p>
                </button>
              </div>
            </Match>
          </Switch>
        </Suspense>
      </div>
    </div>
  );
}
